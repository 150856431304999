@font-face {
    font-family: 'SFU Futura';
    src: url('font/SFUFuturaBook.eot');
    src: url('font/SFUFuturaBook.eot?#iefix') format('embedded-opentype'),
        url('font/SFUFuturaBook.woff2') format('woff2'),
        url('font/SFUFuturaBook.woff') format('woff'),
        url('font/SFUFuturaBook.ttf') format('truetype'),
        url('font/SFUFuturaBook.svg#SFUFuturaBook') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'Futura Std';
    src: url('font/FuturaStd-Light.eot');
    src: url('font/FuturaStd-Light.eot?#iefix') format('embedded-opentype'),
        url('font/FuturaStd-Light.woff2') format('woff2'),
        url('font/FuturaStd-Light.woff') format('woff'),
        url('font/FuturaStd-Light.ttf') format('truetype'),
        url('font/FuturaStd-Light.svg#FuturaStd-Light') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: 'Futura Std';
    src: url('font/FuturaStd-Medium.eot');
    src: url('font/FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('font/FuturaStd-Medium.woff2') format('woff2'),
        url('font/FuturaStd-Medium.woff') format('woff'),
        url('font/FuturaStd-Medium.ttf') format('truetype'),
        url('font/FuturaStd-Medium.svg#FuturaStd-Medium') format('svg');
    font-weight: 500;
}

@font-face {
    font-family: 'InterFace DaMa Lt';
    src: url('font/InterFaceDaMaLt-Bold.eot');
    src: url('font/InterFaceDaMaLt-Bold.eot?#iefix') format('embedded-opentype'),
        url('font/InterFaceDaMaLt-Bold.woff2') format('woff2'),
        url('font/InterFaceDaMaLt-Bold.woff') format('woff'),
        url('font/InterFaceDaMaLt-Bold.ttf') format('truetype'),
        url('font/InterFaceDaMaLt-Bold.svg#InterFaceDaMaLt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



$font-futurabook:'Futura Std';
$font-InterFace-DaMa:'InterFace DaMa Lt';
$color-blue:#0048bb;
$color-orange:#f44c02;


@function vw($element-size) {
	$vwSize: ($element-size / 1900) * 100;
	@return #{$vwSize}vw;
}
@function vw2($element-size) {
	$vwSize: ($element-size / 1200) * 100;
	@return #{$vwSize}vw;
}
@function vw2($element-size) {
	$vwSize: ($element-size / 767) * 100;
	@return #{$vwSize}vw;
}
@mixin font-size($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / 10) + rem;
}

@mixin font-vw($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / (750/100)) + vw; /* 750 = design width, mobile only */
}


/* ====================================================
Position & transform
==================================================== */
@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}

/* ====================================================
Color
==================================================== */

/* ====================================================
Sizing
==================================================== */
$base-size: 16px;
@function px-em($target, $context: $base-size) {
	@if $target == 0 {
		@return 0
	}
	@return $target / $context + 0em;
}

@function calc-vw($size) {
	$vwSize: ($size / 640) * 100;
	@return #{$vwSize}vw;
}

/* ====================================================
Misc
==================================================== */
@mixin no-select {
	 -khtml-user-select: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	     -o-user-select: none;
	        user-select: none;
}

/* ====================================================
Media Quary
==================================================== */
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
$breakpoint: 640px !default;
$breakpoint-sm: 640px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 990px !default;

$breakpoints: (
  'sm': 'screen and (max-width: 640px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 990px)',
  'xl': 'screen and (max-width: 1200px)',
  'xl-36': 'screen and (max-width: 1366px)',
  'xl-1300': 'screen and (max-width: 1300px)',
  'xl-14': 'screen and (max-width: 1400px)',
  'xl-18': 'screen and (max-width: 1800px)',
  'xl-16': 'screen and (max-width: 1600px)',
  'sm-min': 'screen and (min-width: 641px)',
  'md-min': 'screen and (min-width: 769px)',
  'lg-min': 'screen and (min-width: 991px)',
  'xl-min': 'screen and (min-width: 1201px)',
  'xl-max': 'screen and (min-width: 1366px)',
) !default;

//@include mq-max() {
//	CONTENT
//}
