.main-visual{
    position: relative;
    margin-bottom: 90px;
    @include mq{
        margin-bottom: 50px;
    }
	>img{
		@include mq{
			min-height: 200px;
			object-fit: cover;
		}
	}
	img{
		height: 768px;
		object-fit: cover;
		@include mq{
			height: 250px;

		}
	}
	.img__box{
		img{
			height: auto;
		}
	}
    &:after{
        content: "";
        position: absolute;
        height: 200px;
        bottom: -1px;
        left: 0;
        width: 100%;
        background: url(../img/index/bg-06.png) no-repeat bottom center;
        background-size: 100% auto;
        z-index: 2;
		@include mq{
			background-image: url(../img/index/bg-06-sp.png);
		}
    }
    img{
        display: block;
        width: 100%;
    }
    &__ttl{
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        text-transform: uppercase;
        font-weight: 300;
        color:#fff;
        font-size: 48px;
        @include mq{
            font-size: 35px;
        }
    }
    &__image{
        width: 216px;
        position: absolute;
        border-radius: 50%;
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
        bottom:-107px;
        z-index: 5;
        &:before{
            padding-bottom: 100%;
        }
        border:solid 10px #fff;
        @extend .box-shadow;
        @include mq{
            width: 120px;
            bottom:-60px;
			border-width: 5px;
        }
    }
	&--style03{
		&:after{
			display: none;
		}
	}
}

.about{
    &-info{
        padding-bottom: 80px;
        @include mq{
            padding-bottom: 50px;
        }
        .row{
            align-items: flex-start;
        }
        .col-12{
            @include mq{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &-certification{
        list-style: none;
        background: #f6f6f6;
        margin-bottom: 87px;
        padding-top: 80px;
        @include mq{
            margin-bottom: 50px;
            padding-top: 50px;
        }
        svg{
            display: block;
            margin-top: -20px;
        }
        &__list{
            margin-bottom: 0!important;
            padding: 0 40px;
            .slick-slide{
                text-align: center;
                padding: 0 20px;
            }
            @include mq{
                margin-bottom: 20px!important;
            }
			span{
				display: flex;
				justify-content: center;
			}
			img{
				width: auto!important;
				max-width: 150px;
			}
        }
        .slick-list{
        }
        .slick-dots{
            bottom:-45px;
            @include mq{
                bottom:-30px;
            }
        }
        .txt-basic{
            max-width: 920px;
            margin: 0 auto 42px;
        }

	img{
		width: 100%;
		display: block;
	}
    }
    &-environment{
        .container{
            @include mq{
                margin-bottom: 30px;
            }
        }
        .txt-basic{
            margin-bottom: 68px;
            @include mq{
                margin-bottom: 30px;
            }
        }
        .service-content__list{
            margin-bottom: 55px;
            @include mq{
                margin-bottom: 30px;
            }
        }
        .btn-01{
            position: relative;
            z-index: 2;
        }
        svg{
            margin-top: -90px;
        }
    }
    &-team{
        padding-bottom: 108px;
        background: #f6f6f6;
        margin-top: 0;
        padding-top: 100px;
        @include mq{
            padding: 50px 0;
        }
        .txt-basic{
            padding-bottom: 43px;
            margin-bottom: 37px;
            border-bottom: dotted 1px $color-blue;
            @include mq{
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }
        &__img{
            @include mq{
                order:2;
            }
        }
        &__content{
            padding-left: 100px;
            @include mq(xl){
                padding-left: 15px;
            }
            @include mq{
                order:1;margin-bottom: 30px;
            }
        }
    }
}
.object{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    &__role{
        font-weight: bold;
        font-size: 16px;
        color:$color-orange;
        margin-bottom: 20px;
        @include mq{
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
    &__social{
        display: flex;
        list-style: none;
        li{
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
            a{
                display: inline-flex;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                background: #1da1f2;
                color:#fff;
                @include mq{
                    width: 35px;
                    height: 35px;
                }
                i{
                    font-size: 20px;
                    @include mq{
                        font-size: 16px;
                    }
                }
                &:hover{
                    opacity: 1;
                    transform: translateY(3px);
                }
            }
            &.in{
                a{
                    background: #0077b5;
                }
            }
            &.mail{
                a{
                    background: #ea4335;
                }
            }
        }
    }
    &__img{
        width: 130px;
        &:before{
            padding-bottom: 100%;
        }
    }
    &__content{
        padding-left: 20px;
        padding-top: 20px;
    }
}
