.product-list{
	padding-bottom: 80px;
	@include mq{
		padding-bottom: 50px;
	}
	&__ttl{
		background: $color-blue;
		padding: 12px 14px;
		color:#fff;
		font-weight: bold;
		font-size: 16px;
	}
	&__ttl2{
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 13px;
		text-transform: uppercase;
		position: relative;
		pointer-events: none;
		@include mq{
			pointer-events: auto;
		}
		&:after{
			content:"\f107";
			position: absolute;
			top: 3px;
			right: 0;
			font-family: 'Font Awesome 5 Pro';
			display: none;
			color:#888888;
			@include mq{
				top: 0;
			}
		}
		&--sub{
			cursor: pointer;
			&:after{
				display: block;
			}
		}
	}
	.txt-basic{
		margin-bottom: 10px;
	}
	&__content{
		padding: 15px 15px;
		border:solid 1px #ebebeb;
		ul{
			@include mq{
				display: none;
			}
			li{
				margin-bottom: 5px;
			}
		}
	}
	#slider{
		background: #ebebeb;
	}
	&__item{
		margin-bottom: 30px;
	}
	&__filter{
		max-width: 300px;
		@include mq{
			max-width: 100%;
		}
	}
	&__filters{
		color:#666;
		margin-bottom: 24px;
		select{
			height: 40px;
			line-height: 40px;
			margin-bottom: 0;
		}
		&__sort{
			.form-group{
				display: flex;
				align-items: center;
				width: 100%;
				margin-bottom: 0;
				label{
					margin-right: 20px;
					margin-bottom: 0;

				}
			}
			select{
				max-width: 320px;
			}
		}
		&__page{
			display: flex;
			align-items: center;
			span{
				margin-left: 20px;

			}
			select{
				max-width: 80px;
				@include mq{
					max-width: 50px;
				}
			}
		}
	}
	&__reset{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		color:#666;
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: solid 1px #ebebeb;
		span,input,a{
			font-weight: bold;
			font-size: 16px;
			display: inline-block;
			border:0;
			background: none;
			@include mq(lg){
				margin-bottom: 20px;
			}
			@include mq{
				font-size: 14px;
			}
		}
		&__tool{
			padding: 0 20px;
			list-style: none;
			display: flex;
			flex-wrap:wrap;
			li{
				padding: 0 14px;
				@include mq{
					padding: 0 8px;
				}
				span{
					position: relative;
					display: inline-block;
					padding: 5px 20px;
					border:solid 1px #ebebeb;
					font-size: 16px;
					font-weight: normal;
					@include mq{
						padding: 5px 8px;
						font-size: 14px;
					}
					b{
						display: inline-flex;
						justify-content: center;
						align-items: center;
						width: 22px;
						height: 22px;
						border-radius: 50%;
						color:#fff;
						position: absolute;
						top: -11px;
						right: -11px;
						background: $color-orange;
						font-size: 10px;
						cursor: pointer;
						@include mq{
							width: 16px;
							height: 16px;
							font-size: 8px;
							top: -8px;
							right: -8px;
						}
					}
				}
			}
		}
	}
	.form-check{
		padding-left: 0;
		label{
			padding-left: 35px;
			position: relative;
			margin-bottom: 10px;
			cursor: pointer;
		}
		input{
			margin-right: 18px;
			width: 18px;
			height: 18px;
			border-radius: 2px;
			margin-left: -30px;
			 position: absolute;
			  opacity: 0;
			  cursor: pointer;
			  height: 0;
			  width: 0;
			&:checked ~ .checkmark{
				 background: $color-blue;
				&:after {
					  display: block;

					}
			}
		}
		.checkmark {
		  position: absolute;
		  top: 4px;
		  left: 0;
		  height: 18px;
		  width: 18px;
		  background-color: #eee;
		}
		.checkmark:after {
			content: "";
			  position: absolute;
			  display: none;
			  left: 6px;
			  top: 3px;
			  width: 5px;
			  height: 10px;
			  border: solid white;
			  border-width: 0 3px 3px 0;
			  -webkit-transform: rotate(45deg);
			  -ms-transform: rotate(45deg);
			  transform: rotate(45deg);
			}
	}
}
.ui-slider-horizontal .ui-slider-handle{
	background: $color-orange;
	border:0;
	outline:0;
	cursor: pointer;
}
.product-phone{
	@include mq(lg){
		margin: 0 -5px;
	}
	li{
		list-style: none;
		margin-bottom: 30px;
		@include mq(lg){
			padding: 0 5px;
		}
		@include mq{
			margin-bottom: 20px;
		}
	}
	&__wrap{
		border:solid 1px #ebebeb;
		position: relative;
		background: #fcfcfc;
		padding: 10px 26px 26px;
		@include mq(xl){
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	&__discount{
		width: 76px;
		height: 60px;
		position: absolute;
		top: 0;
		right: 0;
		background: url(../img/product/list/shape.png) no-repeat;
		color:#fff;
		font-size: 12px;
		i{
			top: 12px;
		    left: 20px;
			transform: rotate(35deg);
		    display: inline-block;
		    position: absolute;
			text-transform: uppercase;
		}
	}
	&__brand{
		font-size: 14px;
		margin-bottom: 15px;
		display: block;
	}
	.img{
		margin-bottom: 14px;
		@include mq{
			max-width: 200px;
			margin-left: auto;
			margin-right: auto;
		}
		&:before{
			padding-bottom:calc(280/180*100%) ;
		}
		img{
			object-fit: contain;
		}
	}
	&__color{
		margin: 0 -2px 12px;
		list-style: none;
		flex-shrink: 0;
		display: flex;
		li{
			margin: 0 2px;
			height: 15px;
			position: relative;
			input{
				opacity: 0;
				width: 15px;
				height: 15px;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
				&:checked{
					&+span{
						&:before{
							content:"";
							position: relative;
							top: -4px;
							left: -4px;
							width: 23px;
							height: 23px;
							display: inline-block;
							border-radius: 50%;
							border:solid 1px #ebebeb;
						}
					}
				}
			}
			span{
				width: 15px;
				height: 15px;
				flex-shrink: 0;
				display: inline-block;
				border-radius: 50%;
				pointer-events: none;
			}
		}
	}
	.btn-01{
		width: 100%;
		margin-bottom: 10px;
		@include mq(xl){
			padding-left: 5px;
			padding-right: 5px;
			font-size: 12px;
		}
	}
	&__info{
		display: flex;
		justify-content:space-between;
		font-size: 14px;
		margin-bottom: 20px;
		@include mq{
			margin-bottom: 10px;
		}
		span{
			color:#666;
			min-width: 40px;
			display: inline-block;
		}
	}
	&__price{
		color:$color-orange;
		font-size: 22px;
		margin-bottom: 2px;
		small{
			margin-left: 20px;
			font-size: 12px;
			color:#666;
			text-decoration: line-through;

		}
	}
	&__note{
		font-size: 14px;
		@include mq{
			font-size: 13px;
		}
	}
}
.pagination{
	margin: 0 -6px;
	li{
		padding: 0 6px;
		a{
			width: 35px;
			height: 35px;
			border:solid 1px #ebebeb;
			font-size: 14px;
			color:#333;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			&:hover, &.current{
				background: $color-blue;
				color:#fff;
				opacity: 1;
			}
			&.custom{
				width: 70px;
				text-align: center;
			}
		}
	}
}
