.contact{
	.main-visual{
		margin-bottom: 160px;
		@include mq{
			margin-bottom: 100px;
		}
	}
    &-map{
        margin-bottom: 120px;
		.ttl-02{
			margin-bottom: 50px;
		}
        @include mq{
            margin-bottom: 50px;
        }
        &__box{
            border:solid 10px #fff;
            @extend .box-shadow;
            height: 435px;
            @include mq{
                height: 350px;
            }
            iframe{
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
	&-info{
		margin-bottom: 90px;
		@include mq{
			margin-bottom: 50px;
		}
		li{
			list-style: none;
			.wrap{
				border-left: solid 5px $color-blue;
				padding-left: 32px;
				@include mq{
					padding-left: 10px;
					border-width: 3px;
				}
			}
			@include mq{
				margin-bottom: 30px;
				&:last-child{
					margin-bottom: 0;
				}
			
			}
		}
		&__ttl{
			font-weight: 700;
			font-size: 18px;
			color:$color-blue;
			margin-bottom: 20px;
			margin-bottom: 15px;
			@include mq{
				font-size: 16px;
				margin-bottom: 10px;
			}
		}
		p{
			a{
				&:hover{
					color:$color-orange;
				}
			}
		}
	}
	&-form{
		margin-bottom: 60px;
		&__box{
			padding: 50px 80px;
			background: #f6f6f6;
			@include mq(xl){
				padding: 20px 30px;
			}
			@include mq{
				padding: 20px 20px;
			}
			.btn-01{
				max-width: 265px;
			}
			.form-control{
				border:0;
			}
		}
	}
}
