.container{
	max-width: 1490px;
}
a{
	&:hover{
		transition: .5s;
		text-decoration: none;
	}
}
input, select,button, a,.slick-slide{
	&:hover, &:focus{
		outline: none;
	}
}
.box-shadow{
	box-shadow: 0 0 13px 3px rgba(0,0,0,.2);
	@include mq{
		box-shadow: 0 0 8px 1px rgba(0,0,0,.1);
	}
}
.btn{
	&-01{
		display: inline-block;
		background: $color-blue;
		border:solid 1px $color-blue;
		border-radius: 3px;
		color:#fff;
		padding: 5px 23px;
		font-size: 15px;
		text-transform: uppercase;
		text-align: center;
		i{
			margin-left: 15px;
			transition: .4s;
		}

		&--md{
			min-width: 300px;
			padding: 12px 20px;
			i{
				font-size: 16px;
			}
			&:hover{
				i{
					transform: translateX(10px);
				}
			}
			@include mq{
				min-width: 250px;
			}
		}
		&--xs{
			min-width: 160px;
			padding: 10px 13px;
			font-size: 14px;
		}
		&--xxs{
			font-size: 14px;
			border-radius: 2px;
			min-width: 100px;
			padding: 2px 10px;
		}
		&:hover{
			opacity: 1;
			// background: #fff;
			// color:$color-blue;
			background: $color-orange;
			border-color:$color-orange;
			color:#fff;
		}
		@include mq{
			font-size: 13px;
		}
		&--white{
			background: #fff;
			border:solid 1px #fff;
			color:$color-blue;
			min-width: 265px;
			padding: 17px 20px;
			&:hover{
				background: $color-orange;
				border-color:$color-orange;
				color:#fff;
			}
			@include mq{
				padding: 10px 20px;
				min-width: 200px;
			}
		}
		&--orange{
			background: $color-orange;
			border:solid 1px $color-orange;
			&:hover{
				background: #fff;
				color:$color-orange;
			}
		}
	}
	&-02{
		font-size: 14px;
		color:#fff;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		font-weight: 300;
		text-align: center;
		justify-content: center;
		i{
			margin-left: 20px;
			color:$color-orange;
			transition: .4s;
			font-size: 18px;
		}
		&:hover{
			i{
				transform: translateX(10px);
			}
		}
		@include mq{
			font-size: 12px;
			i{
				font-size: 16px;
				margin-left: 7px;
			}
		}
	}
}
img{
	max-width: 100%;
}
//TEXT
.txt{
	&-basic{
		font-size: 16px;
		line-height: 1.75;
		@include mq{
			font-size: 14px;
			line-height: 1.5em;
		}
	}
	&-white{
		color:#fff!important;
	}
	&-des{
		max-width: 920px;
		margin: 0 auto;
	}
	&-blue{
		color:$color-blue!important;
	}
}
.ttl{
	&-01{
		font-size: 20px;
		color:$color-blue;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	&-02{
		font-weight: 500;
		font-size: 36px;
		color:$color-blue;
		padding-bottom: 20px;
		line-height: 1.5;
		position: relative;
		text-align: center;
		margin-bottom: 20px;
		text-transform: uppercase;
		&:after{
			content:"";
			position: absolute;
			left: 50%;
			bottom:0;
			background: $color-orange;
			width: 85px;
			height: 2px;
			transform: translateX(-50%);
		}
		small{
			font-size: 16px;
			color:$color-orange;
			font-weight: normal;
			text-transform: none;
		}

		@include mq{
			padding-bottom: 10px;
			font-size: 24px;
			small{
				font-size: 13px;
			}
		}
		&--black{
			color:#333;
			font-weight: 500;
		}
		&--left{
			text-align: left;
			&:after{
				left: 0;
				transform: translate(0);
			}
		}
		&--none{
			padding-bottom: 0;
			&:after{
				display: none;
			}
		}
		&--md{
			font-size: 24px;
			@include mq{
				font-size: 20px;
			}
		}
	}
	&-03{
		font-weight: 500;
		color:$color-blue;
		margin-bottom: 30px;
		font-size: 18px;

		@include mq{
			font-size: 16px;
		}
		&--md{
			font-size: 16px;
			@include mq{
				font-size: 14px;
			}
		}
	}
	&-sec{
		text-align: left;
		writing-mode:tb-rl;
		color:$color-blue;
		font-size: 14px;
		padding-right: 10px;
		max-height: 150px;
		height: 100%;
		overflow: hidden;
		// border-right: solid 2px $color-orange;
		text-transform: uppercase;
		position: relative;
		padding-top: 10px;
		&:after{
			content:"";
			position: absolute;
			top: 0;
			right: 0;
			width: 1px;;
			height:80px;
			background: $color-orange;
			animation:scroll-down 4s linear infinite;
		}
		@include mq(xl-36){
			display: none;
		}
		@include mq{
			min-height: 130px;
			padding-right: 5px;
		}
	}
}
.flex{
	&-end{
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		justify-content: flex-end;
	}
}
//LIST
.list01{
	list-style: none;
	li{
		padding-left: 28px;
		position: relative;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
		font-size: 16px;
		&:before{
			content:"";
			position: absolute;
			top: 9px;
			left: 0;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background: $color-blue;
		}
		a{
			&:hover{
				opacity: 1;
			}
		}
		@include mq{
			padding-left: 15px;
			&:before{
				top: 11px;
			}
		}
	}
	&--orange{
		li{
			&:before{
				background: $color-orange;
			}
		}
	}
	&--gray{
		li{
			&:before{
				background: #333;
				height: 6px;
				width: 6px;
				top: 9px;
}
	}
}
	&--black{
		li{
			&:before{
				background: #ababab;
				height: 6px;
				width: 6px;
				top: 10px;
			}
		}
	}
}
.list02{
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	li{
		font-size: 20px;
		margin-bottom: 10px;
		i{
			color:$color-blue;
			margin-right: 30px;
		}
		@include mq{
			font-size: 18px;
			i{
				margin-right: 15px;
			}
		}
	}
}
.img{
	position: relative;
	overflow: hidden;
	z-index: 1;
	display: block;
	&:before{
		content:"";
		display: block;
		padding-bottom: 56.25%;
	}
	&__box{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&.w100{
		border-radius: 100%;
		&:before{
			padding-bottom: 100%;
		}
	}
	&.zoom{
		img{
			transition: .7s;
		}
		&:hover{
			img{
				transform: scale(1.02);
			}
		}
	}
}

//FORM
.form-group{
	font-size: 16px;
	margin-bottom: 33px;
	label{
		margin-bottom: 14px;
		@include mq{
			margin-bottom: 10px;
		}
	}
}
.form-control{
	border:0;
	outline: 0;
	box-shadow: none;
	background: #fff;
	padding: 0 15px;
	margin-bottom: 20px;
	font-size: 16px;
	border:solid 1px #ddd;
	&--border-none{
		border:0;
	}
	&::placeholder{
		color:#888888;
	}
	label{
		margin-bottom: 10px;
	}
	&:hover, &:focus{
		outline: 0;
		box-shadow: none;
	}
}
input.form-control, select.form-control{
	height: 60px;
	line-height: 60px;
	padding: 0 20px;
	@include mq{
		height: 40px;
		line-height: 40px;
	}
	&--xs{
		height: 40px;
		line-height: 40px;
	}
}
input[type='file'].form-control{
	border:0;
	padding: 0;
}
textarea.form-control{
	height: 100px;
	resize: none;
	padding: 10px 15px;
}
.modal{
	&--full{
		.modal-dialog{
			max-width: 1000px;
			margin: 0 auto;
			padding: 50px 0;
			@include mq{
				max-width: 90%;
				padding: 20px 0;

			}
		}
		.modal-body{
			padding-left: 30px;
			padding-right: 30px;
			@include mq{
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
	.btn-danger{
		position: absolute;
		top: -20px;
		right: -20px;
		width: 40px;
		height: 40px;
		display: -webkit-inline-flex;
		display: -ms-inline-flex;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		font-size: 20px;
		background: $color-orange;
		border:solid 1px $color-orange;
		&:hover{
			background: #fff;
			color:$color-orange;
		}
	}
}

.bg-white{
	background: #fff;
}
.container{
	@include mq(xl-min){
		@include mq(xl-36){
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}
