.sec-item{
	max-width: 1180px;
	margin: 0 auto 36px;
	&:last-child{
		margin-bottom: 0;
	}
	.txt-basic{
		margin-bottom: 18px;
	}
	>*{
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.page-info{
	padding-top: 106px;
	padding-bottom: 100px;
	border-top: solid 4px $color-blue;
	@include mq{
		padding: 50px 0;
		border-top-width: 2px;
	}
}
