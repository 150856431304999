    .main-visual{
       &--style02{
		   margin-bottom: 0;
		   
	       &:after{
	           background-image: url(../img/service/bg-02-gray.png);
			   @include mq{
				    background-image: url(../img/service/bg-02-gray-sp.png);
			   }
	       }
	   }
	   &--white{
		   &:after{
			   background-image: url(../img/service/bg-02-white.png);
			      @include mq{
   				    background-image: url(../img/service/bg-02-white-sp.png);
   			   }
		   }
	   }
    }

.product{
	.service-intro{
		padding-bottom: 100px;
		@include mq{
			padding-bottom: 50px;
		}
		&--style02{
			padding-bottom: 300px;
			@include mq{
				padding-bottom: 250px;
			}
		}
	}
	&-sell{
		background: url(../img/service/shape01.png) no-repeat top 15px center;
		margin-top: -120px;
		padding-top: 146px;
		padding-bottom: 200px;
		@include mq{
			padding: 70px 0 200px;
		}
		.ttl-02{
			margin-bottom: 70px;
			@include mq{
				margin-bottom: 30px;
			}
		}
		&__list{
			margin-bottom: 150px;
			@include mq{
				margin-bottom: 50px;
			}
			li{
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				text-align: center;
				align-items: center;
				@include mq{
					margin-bottom: 30px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			&__ico{
				@extend .box-shadow;
				border:solid 10px #f3f3f3;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 170px;
				height: 170px;
				margin: 0 auto 53px;
				border-radius: 50%;
				@include mq{
					width: 120px;
					height: 120px;
					border-width: 5px;
					margin-bottom: 30px;
					img{
						max-width: 30px;
					}
				}
			}
			&__ttl{
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 40px;
				@include mq{
					font-size: 16px;
					margin-bottom: 20px;
				}
			}
			.btn-01{
				max-width: 160px;
			}
		}
		.txt-des{
			margin-bottom: 80px;
			@include mq{
				margin-bottom: 40px;
			}
		}
		.list02{
			max-width: 820px;
			margin: 0 auto;
		}
	}
	&-purchase{
		padding: 120px 0 190px;
		background: url(../img/product/sell/bg-01.png) no-repeat top center;
		background-size: 100% 100%;
		margin-top: -200px;
		@include mq(xl-16){
			background-size: cover;
		}
		@include mq{
			padding: 50px 0;
			background-image: url(../img/product/sell/bg-01-sp.png);
		}
		.txt-basic{
			max-width: 1300px;
			margin: 0 auto 50px;
		}
	}

}

.ico{
	@extend .box-shadow;
	border:solid 10px #f3f3f3;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 170px;
	height: 170px;
	margin: 0 auto 53px;
	border-radius: 50%;
	@include mq{
		width: 120px;
		height: 120px;
		border-width: 5px;
		margin-bottom: 30px;
		img{
			max-width: 30px;
		}
	}
}
.quote_step_header{
	background-color: #34495e;
    border-radius: 2rem;
    color: white;
    padding: 10px 20px;
    position: relative;
    font-weight: 400;
    font-size: 1.5em;
	margin-bottom: 20px;
	@include mq{
		font-size: 14px;
		padding: 10px 20px 10px 90px;
	}
	.step{
		background-color: $color-orange;
	    border-radius: 2rem;
	    color: white;
	    padding: 10px 20px;
	    position: absolute;
	    left: 0;
	    top: 0;
	    z-index: 1;
	    margin: auto;
	}
}
.product-form{
	&__wrap{
		background: #fff;
		padding: 35px 90px;
		@include mq(xl){
			padding: 30px 30px;
		}
		@include mq{
			padding: 30px 20px;
		}
	}
	&__ttl{
		border-left: solid 7px $color-orange;
		padding-left: 30px;
		font-size: 18px;
		text-transform: uppercase;
		margin-bottom: 40px;
		span{
			font-weight: bold;
			display: inline-block;
			margin-right: 10px;
		}
		@include mq{
			font-size: 14px;
			padding-left: 10px;
		}
	}
	.btn-01{
		@include mq{
			min-width: 120px;
		}
	}
}
