
body{
    margin-top: 140px;
    @include mq(lg){
        margin-top: 70px;
    }
}
.banner-homepage{
    position: relative;
    height: 620px;
    color:#fff;
    @include mq{
        height: 450px;
    }

    &:after{
        content: "";
        position: absolute;
        height: 200px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: url(../img/index/bg-06.png) no-repeat bottom center;
        background-size: 100% auto;
        z-index: 2;
		@include mq{
			background-image: url(../img/index/bg-06-sp.png);
		}
    }
    &__img{
        text-align: center;
        @include mq(xl){
            img{
                max-width: 300px;
                @include mq{
                    max-width: 220px;
                }
            }
        }
         @include mq{
             margin-top: 40px;
         }
    }
    .overlay{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: #f9e54952;
        }
        #videoIntr{
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .hth_title{
            font-size: 4em;
            line-height: 1.3;
            font-weight: 500;
            letter-spacing: 3px;
            text-shadow:1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px 1px 5px #555;
            margin-bottom: 10px;
            @include mq(xl){
                font-size: 3rem;
            }
            @include mq{
                font-size: 2.5rem;
            }
        }
        .hth_slogan{
            font-size: 16px;
            font-weight: 500;
            @include mq(xl){
                font-size: 14px;
            }
        }
        .container{
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            min-height: 100%;
        }
        .row{
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

// HOME SERVICE#
.home-service{
    padding: vw(250) 0 vw(167);
    background-image: url(../img/index/bg-02.png), url(../img/index/bg-03.png);
    background-repeat: no-repeat;
    background-position: top left, right bottom;
    position: relative;
    margin-top: -50px;
    z-index: 2;
    @include mq(xl){
        margin-top: 0;
    }
    @include mq{
        padding: 150px 0;
    }
    &__list{
        position: relative;
        display: inline-block;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        @include mq{
            max-width: 100%;
            width: 100%;
        }
    }
	&__img{
		width: 100%;
		height: 100%;
		border-radius: 50%;
		overflow: hidden;
	}
    &__link{
        font-size: vw(20);
        text-transform: uppercase;
        color:$color-blue;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: vw(-193);
        // right: calc(-100% + 80px);
        display: inline-block;
        width: auto;
        @include mq{
            font-size: 14px;
            right: auto;
            left: 170px;
            min-width: 200px;
        }
        b{
            width: vw(50);
            height: vw(50);
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: $color-blue;
            border-radius: 50%;
            color:#fff;
            margin-right: vw(16);
            transition: .4s;
            @include mq{
                width: 35px;
                height: 35px;
                margin-right: 14px;
            }
        }
        &:hover{
            opacity: 1;
            color:$color-orange;
            b{
                background: $color-orange;
                transform: translateX(8px);
            }
        }
        &--left{
            @include mq(md-min){
                left: vw(-165);
                right:auto;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                span{
                    order:1;
                }
                b{
                    order:2;
                     margin-left: vw(16);
                     margin-right: 0;
                }
            }
        }
    }
    &__item{

        &--main{
            width: vw(640);
            height: vw(640);
            border-radius: 50%;
            margin: vw(20) auto 0;
            display: flex;
            align-content: center;
            flex-wrap:wrap;
            justify-content: center;
            align-items: center;
            background: url(../img/index/bg-01.png) no-repeat;
            background-size: 100% 100%;
            @include mq(md-min){
                .ttl-02{
                    font-size: vw(36);
                    small{
                        font-size: vw(16);
                    }
                }
                .txt-basic{
                    text-align: center;
                    max-width: vw(380);
                    font-size: vw(16);
                }
            }
            @include mq{
                width: 70%;
                height: auto;
                margin-bottom: 50px;
                .txt-basic{
                    max-width: 70%;
                    font-size: 12px;
                    text-align: center;
                }
                position: relative;
                overflow: hidden;
                background: #fff;
                z-index: 1;
                &:before{
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                }
                @include mq(sm){
                    width: 100%;
                }
            }
            .wrap{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                align-items: center;
                @include mq{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: url(../img/index/bg-01.png) no-repeat;
                    background-size: 100% 100%;
                }

            }
        }

        &--sub{
            position: absolute;
            border-radius: 50%;
            box-shadow: 0 0 13px 3px rgba(0,0,0,.2);
            border:solid vw(10) #fff;
            width: vw(290);
            height: vw(290);
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @include mq{
                width: 200px;
                height: 200px;
                border-width: 5px;
                position: relative;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        &--02, &--03{
            @include mq(md-min){
                width: vw(260);
                height: vw(260);
            }
        }
        &--01{
            @include mq(md-min){
                top: vw(-2);
                left: vw(-418);
            }
        }
        &--02{
            @include mq(md-min){
                top: vw(-06);
                right: vw(-109);
                .home-service__link{
                    right:vw(-190);
                }
            }
        }
        &--03{
            @include mq(md-min){
                bottom: vw(-31);
                left: vw(-95);
                .home-service__link{
                    left: vw(-165);
                }
            }
        }
        &--04{
            @include mq(md-min){
                bottom: vw(-80);
                 right: vw(-408);
                 .home-service__link{
                     left: vw(-175);
                 }
            }
        }
    }
}

.service-content{
    padding: 0 0 50px;
    background: #ebebeb;
    position: relative;
    margin-bottom: 135px;
    .ttl-sec{
        @include mq{
            top: 55px;
        }
    }
    @include mq(xl-18){
        padding-left: 60px;
    }
	@include mq(xl-36){
		padding-left: 0;
	}
    @include mq(xl){
        margin-bottom: 80px;
    }
    @include mq{
        margin-bottom: 40px;
        padding-left: 0;
        padding-top: 20px;
    }
    &:after{
        content:"";
        position: absolute;
        height: 135px;
        bottom:-135px;
        left: 0;
        width: 100%;
        background: url(../img/index/bg-04.png) no-repeat bottom center;
        @include mq(xl){
            bottom:-80px;
            height: 80px;
        }
        @include mq{
            bottom:-40px;
            height: 40px;
        }
    }
	.btn-02{
		justify-content: flex-start;
	}
    .ttl-02{
        @include mq(xl-min){
            font-size: 26px;
        }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }
    &__item{
        width: 33.33%;
        @include mq(xl){
            width: 50%;
        }
        .txt-basic{
            max-width: 690px;
        }
        a{
            display: block;
            position: relative;
            &:after{
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                background: rgba($color-blue,.3);
                opacity: 0;
                z-index: 2;
                transition: .4s;
            }
            &:hover{
                opacity: 1;
                .btn-02{
                    i{
        				transform: translateX(10px);
        			}
                }
                &:after{
                    opacity: 1;
                }
            }
        }

        .img{
            &:before{
                padding-bottom: calc(500/490*100%);
            }
        }
        .inner{
            position: absolute;
            bottom:0;
            left: 0;
            padding: 20px 20px;
            @include mq{
                padding: 10px;
            }
        }
        &--text{
            width: 66.66%;
            @include mq(xl){
                width: 100%;
                margin-bottom: 30px;
            }
            .img{
                &:before{
                    padding-bottom: calc(500/980*100%);
                    @include mq(xl){
                        display: none;
                    }
                }
            }
            .wrap{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding-right: 20px;
                padding-top: 70px;
                @include mq(xl){
                    top:auto;
                    left:auto;
                    position: relative;
                    height: auto;
                }
                @include mq{
                    padding-top: 20px;
                }
            }
        }
    }
     &__name{
         font-size: 20px;
         color:#fff;
         margin-bottom: 10px;
         width: 100%;
         display: block;
         line-height: 1.5;
         b{
             font-size: 60px;
         }
         @include mq{
             font-size: 14px;
             line-height: 1;
             b{
                 font-size: 35px;
             }
         }
     }
}
.ttl-sec{
    position: absolute;
    top: vw(250);
    right: vw(95);
    @include mq(xl-18){
        right: 20px;
    }
    &--left{
        right:auto;
        top: vw(100);
        left: vw(115);
        @include mq(xl-18){
            left: 20px;
        }
        @include mq{
            left: 10px;
        }
    }
}

.home-number{
    padding: 100px 0;
    position: relative;
    @include mq(xl-18){
        padding-right: 60px;
    }
    @include mq{
        padding-right: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .ttl-sec{
        top:vw(135);
        @include mq{
            top: 70px;
        }
    }
    .txt-basic{
        margin-bottom: 40px;
    }
    &__list{
        list-style: none;
        margin-bottom: -30px;
        @include mq{
            margin: 0 -5px -30px -5px;
        }
		&--blue{
			.home-number__wrap{
				background: #417cd9;
			}
			.home-number__ico{
				background: #fff;
			}
			.home-number__ttl{
				color:#fff;
			}
			.home-number__item{
				&:hover{
					.home-number__wrap{
						background: #fff;
					}
					.home-number__ttl{
						color:#333;
					}
					.home-number__ico{
						background: rgba($color-blue,0.8);
						img{
							    filter: brightness(0) invert(1);
						}
					}
				}
			}
		}
    }
    &__item{
        margin-bottom: 30px;
        @include mq{
            padding: 0 5px;
            margin-bottom: 10px;
        }
    }
    &__wrap{
        min-height: 300px;
        background: #f5f5f5;
        transition: .4s;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        &:hover{
            background: $color-blue;
            .home-number__ico{
                background: rgba(255,255,255,.2);
            }
            .home-number__ttl{
                color:#fff;
            }
            img{
                filter: brightness(0) invert(1);
				transform: scale(.8);
            }
        }
        @include mq{
            min-height: 230px;
        }
    }
    &__ico{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #dce3ef;
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        transition: .4s;
		img{
			transition: .5s;
		}
        @include mq{
            width: 80px;
            height: 80px;
            margin-bottom: 15px;
        }
    }
    &__ttl{
        text-align: center;
        font-size: 18px;
        color:#666;
        width: 100%;
        transition: .4s;
        @include mq{
            font-size: 14px;
        }
    }
}


.home-quality{
    background: #f6f6f6;
    padding: 50px 0;
    position: relative;
    @include mq(xl-18){
        padding-left: 60px;
    }
	@include mq(xl-36){
		padding-left: 0;
	}
    @include mq(lg){
        padding-bottom: 100px;
    }
    @include mq{
        padding-bottom: 50px;
        padding-left: 0;
        .ttl-sec{
            top:65px;
        }
        .col-12{
            margin-bottom: 40px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .txt-basic{
        margin-bottom: 20px;
    }
    .list01{
        margin-bottom: 20px;
    }
    .btn-01{
        min-width: 230px;
    }
}


.home-brand{
    background-color: #f6f6f6;
    &__head{
        background:url(../img/index/bg-05.png) no-repeat center top;
        background-size:100% 100%;
        margin-top: -45px;
        position: relative;
        z-index: 2;
        padding-top: 11vw;
        padding-bottom: 140px;
		@include mq(xl-36){
			padding-bottom: 136px;
			background-image: url(../img/index/bg-05-md.png);
		}
        @include mq(xl){
           padding-top: 11vw;
        }
        @include mq(lg){
            background: transparent;
            padding-top: 50px;
        }

    }
    @include mq(lg){
        padding-top: 50px;
        padding-bottom: 50px;
        background:rgba($color-blue, .9) url(../img/index/bg-07.png) no-repeat center top;
		background:#f6f6f6 url(../img/index/bg-05-sp.png) no-repeat center top;
		background-size: 100% 100%;
		@include mq(sm){
			background-size: auto;
		}
    }
    .txt-basic{
        max-width: 920px;
        margin: 0 auto;
    }
    &__list{
        background: #fff;
        padding: 56px vw(130);
        z-index: 2;
        box-shadow: 0 0 13px 3px rgba(0,0,0,.2);
        margin-top: -90px;
		@include mq(xl-36){
			padding-top: 42px;
			padding-bottom: 42px;
			margin-top: -120px;
		}
        @include mq{
            padding: 40px 30px 60px;
			margin-top: -90px;
        }
        .slick-slide{
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
        }

        a{
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            img{
                transition: .4s;
            }
            &:hover{
                opacity: 1;
                img{
                    filter: grayscale(100%);
                      -webkit-filter: grayscale(100%);
                      -moz-filter: grayscale(100%);
                }
            }
        }
    }
}

 .slick-dots{
     position: absolute;
     bottom:20px;
     left: 0;
     width: 100%;
     display: -webkit-flex;
     display: -ms-flex;
     display: flex;
     justify-content: center;
     li{
         width: 10px;
         height: 10px;
         margin: 0 6px;
         padding: 0;
         background: #ddd;
         cursor: pointer;
         border-radius: 50%;
         button{
             display: none;
         }
         &.slick-active{
             background: $color-orange;
         }
     }
 }
