.product-detail{
	&__phone{
		margin: 0 -35px 63px;
		@include mq(xl){
			margin-left: -10px;
			margin-right: -10px;
		}
		>*{
			padding: 0 35px;
			@include mq(xl){
				padding: 0 10px;
			}
		}
		&__img{
			@include mq(md-min){
				flex: 0 0 calc(615/1470*100%);
	    		max-width: calc(615/1470*100%);
			}
			@include mq{
				margin-bottom: 30px;
			}
		.slider-for{
			margin-bottom: 15px;
			background: #fcfcfc;
			border:solid 1px #ebebeb;
			.img{
				&:before{
					padding-bottom: 100%;
				}
				&__box{
					display: flex;
					justify-content: center;
					align-items: center;
				}
				img{
					object-fit: contain;
					max-width: 80%;
					max-height: 80%;
				}
			}
		}
		.slider-nav{
			.slick-list{
				margin: 0 -10px;
			}
			li{
				padding: 0 10px;
				&.slick-current{
					.img{
						border-color: $color-orange;
					}
				}
				.img{
					cursor: pointer;
					background: #fcfcfc;
					border:solid 1px #ebebeb;
					&:before{
						padding-bottom: 100%;
					}
					img{
						object-fit: contain;
					}
				}
			}
		}
	}
	&__info{
		@include mq(md-min){
			flex: 0 0 calc(855/1470*100%);
			max-width: calc(855/1470*100%);
		}
		.ttl-02{
			line-height: 1.2;
			margin-bottom: 10px;
		}
		.btn-01--xxs{
			margin-bottom: 20px;
			pointer-events: none;

		}
		}
	}
	&__price{
		font-weight: 500;
		font-size: 36px;
		margin-bottom: 20px;
		color:$color-orange;
		small{
			font-size: 18px;
			font-weight: 300;
			color:#888888;
			margin-left: 10px;
		}
	}
	&__color{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		.product-phone__color{
			width: 100%;
			margin-bottom: 0;
		}
		span{
			margin-right: 20px;
		}
	}
	&__size{
		list-style: none;
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.txt{
			margin-right: 17px;
			@include mq{
				font-size: 10px;
			}
		}
		ul{
			margin: 0 -8px;
			display: flex;
			flex-wrap: wrap;
			li{
				padding: 0 8px;
				list-style: none;
				position: relative;
			}
			input{
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
				&:checked{
					&+span{
						border-color:$color-blue;
					}
				}
			};
			span{
				display: inline-block;
				padding: 7px 14px;
				background: #fcfcfc;
				border:solid 1px #ebebeb;
				@include mq{
					padding: 5px 7px;
					font-size: 12px;
				}
			}
		}
	}
	&__info{
		background: #fbfbfb;
		padding: 20px 15px;
		border:dashed 1px #ebebeb;
		list-style: none;
		margin-bottom: 30px;
		li{
			display: flex;
			margin-bottom: 10px;
			span{
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 90px;
				text-align: center;
			}
			b{
				display: inline-block;
				padding-left: 20px;
				width: calc(100% - 90px);
				font-weight: 400;
			}
		}
	}
	&__ttl2{
		font-size: 30px;
		text-transform: uppercase;
		margin-bottom: 20px;
		font-weight: 400;
		@include mq{
			font-size: 20px;
		}
	}
	&__des{
		margin-bottom: 50px;
		&__ttl{
			margin-bottom: 20px;
			border-bottom: solid 1px #ebebeb;
			.btn-01{
				border-radius: 0;
				&:hover{
					background: $color-blue;
					border-color:$color-blue;
					color:#fff;
				}
			}
		}
		p{
			font-size: 16px;
			line-height: 1.5;
		}
	}
	&__other{
		margin-bottom: 100px;
		.product-phone{
			.slick-dots{
				bottom:-30px;
			}
			.slick-list{
				margin: 0 -25px;
				@include mq(xl){
					margin: 0 -5px;
				}
			}
			.slick-slide{
				padding: 0 25px;
				@include mq(xl){
					padding: 0 5px;
				}
			}
		}
	}
}
