
body{
    font-family: $font-futurabook;
    color:#000;
    line-height: 1.5;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
    line-height: 24px;
    overflow-x: hidden;
     display:flex;
     flex-direction:column;
    min-height:100vh;
    &.on{
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
	@include mq(lg){
		margin-top: 70px!important;
	}
}
.pc{
	@include mq{
		display: none!important;
	}
    &-lg{
        @include mq(lg){
            display: none!important;
        }
    }
}
.sp{
	@include mq(md-min){
		display: none!important;
	}
    &-lg{
        @include mq(lg-min){
    		display: none!important;
    	}
    }
}
a{
    color:inherit;
    transition: .4s;
    &:hover{
        opacity: .7;
    }
}
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255,255,255,.9);
    min-height: 100px;
    z-index: 10;
    @include mq(lg){
        min-height: 70px;
        padding-right: 50px;
    }

    .container{
        display: flex;
        align-items: center;
        min-height: inherit;
    }
    &__logo{
        width: 20%;
        @include mq{
            img{
                max-width: 120px;
            }
        }
    }
    &__right{
        width: 80%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn-01{
            margin-left: 56px;
            text-transform: uppercase;
            @include mq(xl){
                margin-left: 25px;
                font-size: 13px;
                padding: 5px 15px;
            }
            @include mq{
                font-size: 11px;
                padding: 5px 10px;
            }
        }
    }

    &__menu{
        ul{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0 -24px;
            list-style: none;
            li{
                padding: 0 24px;
                a{
                    display: inline-block;
                    padding: 0 12px;
                    font-size: 16px;
                    font-family: $font-futurabook;
                    position: relative;
                    text-transform: uppercase;
                    transition: .3s;
                    @include mq(lg-min){
                        line-height: 100px;
                        height: 100px;
                    }
                    &:after{
                        content:"";
                        position: absolute;
                        left: 0;
                        bottom:0;
                        height: 0;
                        opacity: 0;
                        width: 100%;
                        background: $color-blue;
                        transition: .4s;
                    }
                }
                &:hover, &.active{
                    @include mq(lg-min){
                        a{
                            opacity: 1;
                            color:$color-blue;
                            &:after{
                                height: 4px;
                                opacity: 1;
                            }
                        }
                    }
                }
                @include mq(xl-1300){
                    padding: 0 10px;
                }
                @include mq(xl){
                    padding: 0 5px;
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
        @include mq(lg){
			position: fixed;
			top: 70px;
			right: -100%;
			width: 100%;
			height: calc(100vh - 08px);
			overflow-y: auto;
			background-color: #fff;
			transition: .4s;
			z-index: 10;
			border-top: 2px solid $color-blue;
			background-color: $color-blue;
			&.on{
				right: 0;
			}
            ul{
                margin: 0;
                flex-wrap: wrap;
				li{
					width: 100%;
					position: relative;
                    padding: 0;
					a{
						display: inline-block;
						width: 100%;
						font-size: 16px;
						padding: 15px 20px;
						line-height: 1;
						&:after{
							display: none!important;
						}
						color:#fff;
						background-color: $color-blue;
						border-bottom: 1px solid #fff;
						&:hover{
							opacity: 1;
							text-decoration: none;
						}
					}
					.buttons{
						position: absolute;
						width: 47px;
						height: 47px;
						top: 0;
						right: 0;
						&:before{
							width: 15px;
							height: 2px;
							content:"";
							position: absolute;
							top: 50%;
							left: 50%;
							background-color: #fff;
							transform: translate(-50%, -50%);
						}
						&:after{
							height: 15px;
							width: 2px;
							content:"";
							position: absolute;
							top: 50%;
							left: 50%;
							background-color: #fff;
							transform: translate(-50%, -50%);
							transition: .4s;
						}
						&.on{
							&:after{
								height: 0;
							}
						}
					}
					&:last-child{
						a{
							border-bottom: 0;
						}
					}
					ul{
						display: none;
						li{
							a{
								padding: 12px 30px;
								font-weight: 500;
								font-size: 14px;
								background-color:#26afaef2;
							}
							&:last-child{
								a{
									border-bottom: 1px solid #fff;
								}
							}
						}
						.right{
							li{
								a{
									font-size: 13px;
									font-weight: 400;
									font-style: italic;
								}
							}
						}
					}
				}
            }
		}
    }
}
.header02{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 10;
            background: #fff;
            @include mq(lg){
                padding-right: 50px;
                height: 70px;
            }
			&.scroll{
				border-bottom: 2px solid #ddd;
				box-shadow: 0 10px 13px 3px rgba(0,0,0,.2);
				@include mq(lg-min){
					.header02__logo{
						img{
							max-height: 35px;
						}
					}
					.header02__top__search input{
						height: 30px;
					}
					.header02__top__search button{
						width: 30px;
						height: 30px;
						line-height: 30px;
					}
					.header02__top .row{
						min-height: 45px;
					}
					.header02__menu ul>li{
						padding: 7px 0;
						a{
							line-height: 35px;
							font-size: 16px;
							padding: 0 30px;
							@include mq(xl){
								font-size: 13px;
								padding-left: 20px;
								padding-right: 20px;
							}
						}
						ul{
							li{
								padding: 0;
								a{
									font-size: 14px;
									line-height: 40px;
									@include mq(xl){
										font-size: 12px;
									}
								}
							}
						}
					}
				}
			}
            &__top{
                padding: 0;
                border-bottom: solid 1px #ddd;
                @include mq(lg){
                    border-bottom: 0;
                    padding: 0;
                }
                .row{
                    align-items: center;
                    min-height: 70px;
                }
                &__left{
                    display: flex;
                    align-items: center;
                }
                &__search{
                    display: flex;
                    justify-content: flex-end;
                    @include mq{
                        display: none;
                    }
                    input{
                        width: 200px;
                        height: 40px;
                        padding: 0 20px;
                        border:solid 1px #ddd;
                        border-radius: 3px;
                       @include mq{
                            &:hover{
                                border-color:$color-orange;
                            }
                       }
                    }
                    button{
                        width: 40px;
                        height: 40px;
                         border-radius: 3px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        background: $color-orange;
                        border:solid 1px $color-orange;
                        color:#fff;
                        font-size: 18px;
                        outline: none;
                        transition: .4s;
                        &:hover, &:focus{
                            outline: none;
                        }
                        @include mq(md-min){
                            &:hover{
                                background: #fff;
                                color:$color-orange;
                            }
                        }
                    }
                }
            }
            &__logo{
                margin-right: 20px;
                @include mq{
                    img{
                        max-width: 120px;
                    }
                }
            }

            &__info{
                list-style: none;
                display: flex;
                @include mq(lg){
                    display: none;
                }
                li{
                    margin-right: 10px;
                    &:last-child{margin-right: 0;}
                    font-size: 16px;
                    a{
                        display: flex;
                        align-items: center;
                        &:hover{
                            color:$color-orange;
                        }
                    }
                    i{
                        color:$color-orange;
                        margin-right: 5px;
                        font-size: 20px;
                    }
                }
            }
            &__right{
                width: 80%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .btn-01{
                    margin-left: 56px;
                    text-transform: uppercase;
                    @include mq(xl){
                        margin-left: 25px;
                        font-size: 13px;
                        padding: 5px 15px;
                    }
                    @include mq{
                        font-size: 11px;
                        padding: 5px 10px;
                    }
                }
            }

            &__menu{
                @include mq(md-min){
                    .header02__top__search{
                        display: none!important;

                    }
                }
                .header02__top__search{
                    display: flex;
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    form{
                        width: 100%;
                        display: -webkit-flex;
                        display: -ms-flex;
                        display: flex;
                    }
                    input{
                        width: calc(100% - 40px);
                        border:0;
                        outline: 0;
                        box-shadow: none
                        &:hover, &:focus{
                            border:0;
                            outline: 0;
                        }
                    }
                }
                ul{
                    display: flex;
                    align-items: center;
                    list-style: none;
                    >li{
                        padding: 15px 0;
                        position: relative;
                        &.has-sub{
                            >a{
                                &:after{
                                    content:"\f0d7";
                                    display: inline-block;
                                    font-family: 'Font Awesome 5 Pro';
                                    margin-left: 5px;
                                    height: 12px;
                                    line-height: 10px;
                                    position: relative;
                                    top: 0;
                                }
                            }
                        }
                        >a{
                            display: inline-flex;
                            align-items: center;
                            padding: 0 40px;
                            font-size: 18px;
                            font-family: $font-futurabook;
                            position: relative;
                            text-transform: uppercase;
                            transition: .3s;
                            font-weight: 500;
                            line-height: 40px;
                            border-radius: 25px;
                            &.active{
                                @include mq(lg-min){
                                    opacity: 1;
                                    background: $color-blue;
                                    color:#fff;
									&:hover{
										color:#fff;
									}
                                }
                            }
                            &:hover{
                                @include mq(lg-min){
                                    opacity: 1;
                                    color: $color-blue;
                                }
                            }
                            @include mq(xl-14){
                                font-size: 16px;
                                padding: 0 25px;
                            }
                        }

                        >ul{
                            @include mq(lg-min){
                                position: absolute;
                                top: 120%;
                                width: 280px;
                                left: 0;
                                background: #fff;
                                box-shadow: 0 0 13px 3px rgba(0,0,0,.2);
                                opacity: 0;
                                pointer-events: none;
                                flex-wrap: wrap;
								transition: .3s;
                            }
                            li{
                                width: 100%;
                                padding: 0;
                                a{
                                    padding: 15px 20px;
                                    line-height: 1.2;
                                    font-size: 14px;
                                    border-radius: 0;
                                    width: 100%;
                                    &:hover{
                                        @include mq(lg-min){
                                            background: $color-blue;
                                            color:#fff;
                                        }
                                    }
                                    &:after{
                                        transform: rotate(270deg);
                                    }
                                }
                                 @include mq(lg-min){
                                     &:hover{
                                         >ul{
                                             top: 0!important;
                                             opacity: 1;
                                             pointer-events: auto;
                                         }
                                     }
                                     ul{
                                         position: absolute;
                                         top: 0;
                                         width: 280px;
                                         right:-100%;
                                         left:auto;
                                         background: #fff;
                                         box-shadow: 0 0 13px 3px rgba(0,0,0,.2);
                                         opacity: 0;
                                         pointer-events: none;
                                         flex-wrap: wrap;
                                     }
                                 }

                            }
                        }
                        @include mq(lg-min){
                            &:hover{
                                >ul{
                                    top:100%;
                                    opacity: 1;
                                    pointer-events: auto;
                                }
                            }
                        }
                        @include mq(xl-1300){

                        }
                        @include mq(xl){
                            a{
                                font-size: 14px;
                            }
                        }
                    }
                }
                @include mq(lg){
        			position: fixed;
        			top: 70px;
        			right: -100%;
        			width: 100%;
        			height: calc(100vh - 08px);
        			overflow-y: auto;
        			background-color: #fff;
        			transition: .4s;
        			z-index: 10;
        			border-top: 2px solid $color-orange;
        			background-color: $color-orange;
                    @include mq{
                        padding-top: 20px;
                    }
        			&.on{
        				right: 0;
        			}
                    ul{
                        margin: 0;
                        flex-wrap: wrap;
        				li{
        					width: 100%;
        					position: relative;
                            padding: 0;
        					a{
        						display: inline-block;
        						width: 100%;
        						font-size: 16px;
        						padding: 15px 20px;
        						line-height: 1;
                                border-radius: 0;
        						&:after{
        							display: none!important;
        						}
        						color:#fff;
        						background-color: $color-orange;
        						border-bottom: 1px solid #fff;
        						&:hover{
        							opacity: 1;
        							text-decoration: none;
        						}
        					}
        					.buttons{
        						position: absolute;
        						width: 47px;
        						height: 47px;
        						top: 0;
        						right: 0;
                                cursor: pointer;
        						&:before{
        							width: 15px;
        							height: 2px;
        							content:"";
        							position: absolute;
        							top: 50%;
        							left: 50%;
        							background-color: #fff;
        							transform: translate(-50%, -50%);
        						}
        						&:after{
        							height: 15px;
        							width: 2px;
        							content:"";
        							position: absolute;
        							top: 50%;
        							left: 50%;
        							background-color: #fff;
        							transform: translate(-50%, -50%);
        							transition: .4s;
        						}
        						&.on{
        							&:after{
        								height: 0;
        							}
        						}
        					}
        					&:last-child{
        						a{
        							border-bottom: 0;
        						}
        					}
        					ul{
        						display: none;
        						li{
        							a{
        								padding: 12px 30px;
        								font-weight: 500;
        								font-size: 14px;
                                        text-transform: none;
        							}
        							&:last-child{
        								a{
        									border-bottom: 1px solid #fff;
        								}
        							}
                                    ul{
                                        li{
                                            a{
                                                padding-left: 50px;
                                                padding-right: 50px;
                                                font-style: italic;
                                            }
                                        }
                                    }
        						}
        						.right{
        							li{
        								a{
        									font-size: 13px;
        									font-weight: 400;
        									font-style: italic;
        								}
        							}
        						}
        					}
        				}
                    }
        		}
            }
}
//HAMBURGE
.hamburger {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 35px;
    height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.hamburger span {
    position: relative;
    display: block;
    width: 25px;
    height: 3px;
	background: $color-orange;
	transition: all 0.4s;
}
.hamburger span:before, .hamburger span:after {
    content: '';
    display: block;
    height: 3px;
    left: 0;
    background: inherit;
    position: absolute;
    transition: all 0.4s;
    -webkit-animation: gradientbgAnim 49s ease infinite;
    -moz-animation: gradientbgAnim 49s ease infinite;
    -o-animation: gradientbgAnim 49s ease infinite;
    animation: gradientbgAnim 49s ease infinite;
}
.hamburger span:before {
    top: -15px;
      width: 15px;
}
.hamburger span:after {
    bottom: -15px;
      width: 35px;
}

.hamburger {
    transform: translate(0, -50%);
}
.hamburger.closes span {
  width: 0px;
}
.hamburger.closes span:before {
    top: 0;
  width: 35px;
    transform: rotate(45deg);
}
.hamburger.closes span:after {
    bottom: 0;
    width: 35px;
    transform: rotate(-45deg);
}
//footer
.btn-top{
	position: fixed;
	width: 40px;
	height: 40px;
	background: $color-orange;
	right: 20px;
	bottom:40px;
	color:#fff;
	font-size: 20px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	transition: .8s;
	padding-top: 8px;
	&.on{
		top: -20px;
		bottom:auto;
		position: absolute;
	}
	&:hover{
		opacity: 1;
		color:#fff;
	}
	@include mq{
		right: 10px;
	}
	i{
	    animation-name: hvr-icon-bob-float, hvr-icon-bob;
	    animation-duration: .3s, 1.5s;
	    animation-delay: 0s, .3s;
	    animation-timing-function: ease-out, ease-in-out;
	    animation-iteration-count: 1, infinite;
	    animation-fill-mode: forwards;
	    animation-direction: normal, alternate;
	}
}
.footer{
    margin-top: auto;
    &__main{
        padding: 110px 0 60px;
        background:#f6f6f6 url(../img/common/bg-footer.png) no-repeat center center;
        @include mq{
            padding: 50px 0 50px;
        }
		.contact-info__list {
			list-style: none;
			margin-bottom: 50px;
		}
        .col-12{
            @include mq(md-min){
                &:nth-child(1){
                    flex: 0 0 39%;
                    max-width: 39%;
                }
                &:nth-child(2){
                    flex: 0 0 30%;
                    max-width: 30%;
                }
                &:nth-child(3){
                    flex: 0 0 31%;
                    max-width: 31%;
                }
            }
            @include mq{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &__logo{
        margin-bottom: 25px;
    }
    .txt-basic{
        margin-bottom: 30px;
        max-width: 335px;
    }
    &__social{
        list-style: none;
        display: flex;
        li{
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
            a{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border:solid 1px $color-blue;
                color:$color-blue;
                font-size: 16px;
                &:hover{
                    background: $color-blue;
                    color:#fff;
                    opacity: 1;
					font-size: 19px;
                }
            }
        }
    }
}
.copyright{
    padding: 10px 0;
    color:#fff;
    background: $color-blue;
	position: relative;
}

.page{
    border-bottom:solid 1px #e1e1e1;
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
//PRELOAD
#pageloader{
	width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 99999;
	color:$color-blue;
	.content{
		position: relative;
	}
	.outter{
		// position: absolute;
	    // top: 50%;
	    // left: 50%;
	    // margin: -40px 0 0 -40px;
	    // width: 100px;
	    // height: 100px;
	    border-radius: 100px 0 0 100px;
	    border-left: solid 8px;
		position: absolute;
	       top: 15px;
    left: 22px;
	    margin: -40px 0 0 -40px;
	    width: 130px;
	    height: 130px;
	    animation: spin 1.3s linear 0s infinite normal;
	}
	.mid{
		top: 15px;
	    left: 15px;
	    position: relative;
	    width: 60px;
	    height: 60px;
	    border-radius: 70px 0 0 70px;
	    border-left: solid 4px;
	    animation: spin 3.3s linear 0s infinite normal;
	}
}
#pageloader{
	display: flex;
	justify-content: center;
	align-items: center;
}
