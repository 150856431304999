.list-serve{
	list-style: none;
	margin: 0;
	padding-top: 50px;
	@include mq{
		padding-top: 30px;
	}

	&__number{
		font-family: $font-InterFace-DaMa;
		font-size: vw(280);
		font-size: 280px;
		font-weight: bold;
		position: relative;
		margin-bottom: 16px;
		color:$color-orange;
		.ico{
			width: 170px;
			height: 170px;
			background: #fff;
			border-color: #f3f3f3;
			box-shadow: none;
			position: relative;
			z-index: 2;
			margin: 0;
			margin-left: 90px;
			transform: translateY(-16px);
		}
		@include mq(xl){
			font-size: 180px;
			.ico{
				width: 100px;
				height: 100px;
				margin-left: 58px;
				img{
					max-width: 50px;
				}
			}
		}
		@include mq{
			width: 190px;
			margin: 0 auto 10px;
		}
	}
	&__ttl{
		text-align: center;
		font-weight: bold;
		font-size: 18px;
		line-height: 1.67;
		text-transform: uppercase;
		margin-bottom: 45px;
		@include mq(xl){
			font-size: 16px;
		}
		@include mq{
			margin-bottom: 15px;
		}
	}
	&__box{
		padding: 28px 42px 44px;
		background: #f6f6f6;
		font-size: 16px;
		width: 400%;
		display: none;
		@include mq{
			width: 100%;
			padding: 20px 20px;
			font-size: 14px;
			display: block;
		}
	}
	&__img{
		border-radius: 3px;
		// border:solid 1px #ddd;
		margin-bottom: 30px;
		transition: .4s;
		&:before{
			padding-bottom: calc(180/330*100%);
		}
		.img__box{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		img{
			max-width: 80%;
			max-height: 80%;
			object-fit: contain;
		}
	}
	.arrow{
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
      	height: 0;
		top:0;
	      border-left: 17px solid transparent;
	      border-right: 17px solid transparent;
	      border-bottom: 17px solid #f6f6f6;
		  display: none;
		  @include mq{
			  display: block;
		  }
	}
	li{
		cursor: pointer;
		padding: 0;
		@include mq{
			display: flex;
			flex-direction: column;
			align-content: center;
			justify-content: center;
			margin-bottom: 70px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&:hover{
			// .list-serve__img{
			// 	border-color:$color-orange;
			// }
			.ico{
				background: $color-blue;
				img{
					filter: brightness(0) invert(1);
				}
			}
			// .list-serve__ttl{
			// 	color:$color-blue;
			// }
			.list-serve__box{
				display: block;
			}
			.arrow{
				display: block;
			}
		}
		&:nth-child(2n+2){
			.list-serve__box{
				margin-left: -100%;
				@include mq{
					margin-left: 0;
				}
			}
		}
		&:nth-child(3n+3){
			.list-serve__box{
				margin-left: -200%;
				@include mq{
					margin-left: 0;
				}
			}
		}
		&:nth-child(4n+4){
			.list-serve__box{
				margin-left: -300%;
				@include mq{
					margin-left: 0;
				}
			}
			.ico{
				margin-left: 75px;
			}
		}
	}
	&--style02{
		margin-left: -25px;
		margin-right: -25px;
		@include mq(xl){
			margin-left: -10px;
			margin-right: -10px;
		}
		li{
			.list-serve__box{
				width: calc(400% + 150px);
				@include mq(xl){
					width: calc(400% + 60px);
				}
				@include mq{
					width: 100%;
				}
			}
			padding: 0 25px;
			@include mq(xl){
				padding: 0 10px;
			}
			&:nth-child(2n+2){
				.list-serve__box{
					margin-left: calc(-100% - 50px);
					@include mq(xl){
						margin-left: calc(-100% - 20px);
					}
					@include mq{
						margin-left: 0;
					}
				}
			}
			&:nth-child(3n+3){
				.list-serve__box{
					margin-left: calc(-200% - 100px);
					@include mq(xl){
						margin-left: calc(-200% - 40px);
					}
					@include mq{
						margin-left: 0;
					}
				}
			}
			&:nth-child(4n+4){
				.list-serve__box{
					margin-left: calc(-300% - 150px);
					@include mq(xl){
						margin-left: calc(-300% - 60px);
					}
					@include mq{
						margin-left: 0;
					}
				}
			}
		}
		.list-serve__box{
			background: #f6f6f6;
		}
		.arrow{
			border-bottom-color:#f6f6f6;
		}
	}
	&--style03{
		margin: 0 -25px -210px;
		@include mq(xl){
			margin-bottom: -150px;
		}
		@include mq{
			margin-bottom: -100px;
		}
		li{
			margin-bottom: 210px;
			padding: 0 25px;
			@include mq(xl){
				margin-bottom: 150px;
			}
			@include mq{
				margin-bottom: 100px;
			}
		}
		.list-serve__number{
			padding-left: 50px;
			margin-bottom: 22px;
			@include mq{
				width: 200px;
				padding-left: 0;
			}
		}
		.list-serve__ttl{
			margin-bottom: 10px;
		}
		.img{
			border:solid 10px #fff;
			max-width: 290px;
			margin-left: 53px;
			@include mq(xl){
				margin-left: 40px;
			}
			@include mq{
				max-width: 150px;
				margin-left: 50px;
			}
		}
	}
}
.list-sustain{
	li{
		text-align: center;
		list-style: none;
		@include mq{
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	.ico{
		margin-bottom: 35px;
		border:solid 10px #f3f3f3;
		box-shadow: none;
		background: #fff;
		@include mq(md-min){
			width: 150px;
			height: 150px;
		}
		@include mq{
			margin-bottom: 10px;
		}
	}
	&__ttl{
		font-weight: bold;
		font-size: 18px;
		text-transform: uppercase;
		margin-bottom: 10px;
		@include mq{
			font-size: 16px;
		}
	}
}
