.box{
	list-style: none;
	margin-bottom: 50px;
	padding-top: 50px;
	@include mq{
		padding-top: 20px;
	}
	li{
		@include mq{
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	&__img{
		margin-bottom: 30px;
		@include mq{
			margin-bottom: 10px;
		}
	}
	&__ttl{
		font-size: 30px;
		margin-bottom: 10px;
		font-weight: 500;
		@include mq{
			font-size: 20px;
			margin-bottom: 5px;
		}
	}
	&__content{
		padding-left: 30px;
		position: relative;
		&:before{
			content:"";
			position: absolute;
			width: 5px;
			height: 85px;
			top: 5px;
			left: 0;
			background: $color-orange;
			@include mq{
				height: 60px;
			}
		}
	}
}
.list-brand{
	list-style: none;
	margin: 0 -10px;
	li{
		padding: 0 10px;
		@include mq{
			margin-bottom: 20px;
		}
		.img{
			&:before{
				border:solid 1px #ebebeb;
				border-radius: 3px;
			}
			&__box{
				justify-content: center;
				align-items: center;
				display: flex;
			}
			img{
				object-fit: contain;
				max-width: 80%;
				max-height: 80%;
			}
		}
	}
}
.sec-industry{
	&__left{
		padding-right: 90px;
		@include mq(xl-14){
			padding-right: 15px;
		}
		@include mq{
			margin-bottom: 30px;
		}
	}
}
.product-purchase{
	&.style02{
		padding-bottom: 100px;
		padding-top: 80px;
		background: #fff url(../img/why-choose-us/bg01.png) no-repeat top center;
		// @include mq(xl-16){
		// 	background-size:100% auto;
		// }
		@include mq{
			padding-bottom: 70px;
		}
	}
}
