.service{
    .main-visual{
       margin-bottom: 0;
       &:after{
           background-image: url(../img/service/bg-02-gray.png);
       }
    }
    &-intro{
        background: #f6f6f6;
        padding-top: 140px;
		padding-bottom: 100px;
		@include mq{
			padding: 50px 0;
		}
        .txt-des{
            margin-bottom: 100px;
            @include mq{
                margin-bottom: 30px;
            }
        }
        &__feature{
            background: url(../img/service/shape01.png) no-repeat top 15px center;
            @include mq{
                background: transparent;
            }
            // background-size: 100% auto;
            ul{
                background: #fff;
                list-style: none;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px;
                padding: 20px 70px;
                border-radius: 70px;
                @extend .box-shadow;
                @include mq{
                    box-shadow: none;
                    padding: 0;
                    background: transparent;
                    border-radius:0;
                }
                li{
                    width: 33.33%;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                }
                @include mq(xl){
                    padding: 20px 20px;
                    margin: 0;
                }
                @include mq{
                    padding: 20px 20px;
                    li{
                        width: 100%;
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &__ico{
                margin-right: 25px;
                width: 100px;
                height: 100px;
                 flex-shrink: 0;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border:solid 10px #f0f0f0;
                position: relative;
                @include mq(xl){
                    width: 80px;
                    height: 80px;
                    margin-right: 15px;
                }
            }
            .txt-basic{
                font-size: 18px;
            }
        }
    }
    &-business{
        padding-bottom: 100px;
        padding-top: 116px;
        @include mq{
            background: #f6f6f6;
            padding-bottom: 50px;
            padding-top: 30px;
        }
        &__list{
            margin: 0 -60px;
            @include mq(xl){
                margin: 0 -20px;
            }
			.txt-basic{
				text-align: center;
			}
        }
        &__item{
            padding: 0 60px;
            @include mq(xl){
                padding: 0 20px;
            }
            @include mq{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        &__img{
            width: 100%;
            max-width: 520px;
            margin: 0 auto 70px;
            overflow: initial;
            border-radius: 50%;
            @include mq{
                max-width: 250px;
                margin-bottom: 40px;
            }
            .img__box{
                 border-radius: 50%;
                 img{
                     transition: .7s;
                      border-radius: 50%;
                      cursor: pointer;
                 }
            }

            &:before{
                padding-bottom: 100%;
            }
            &:hover{
                .img__box{
                    img{
                        filter: grayscale(100%);
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                    }
                }
            }
        }
        &__ico{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border:solid 10px rgba(255,255,255,.5);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom:-50px;
            flex-shrink: 0;
            span{
                background: #0048bb;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                 border-radius: 50%;
            }
            @include mq{
                border-width: 5px;
                width: 70px;
                height: 70px;
                bottom:-35px;
            }
        }
        &__ttl{
            text-align: center;
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 14px;
            text-transform: uppercase;
            color:$color-blue;
            @include mq{
                font-size: 24px;
            }
        }
    }
	&-understand{
		background: url(../img/service/shape01.png) no-repeat top 15px center;
	    margin-top: -120px;
	    padding-top: 146px;
	    padding-bottom: 300px;
		@include mq{
			padding: 70px 0 250px;
		}
		&__list{
			margin-bottom: -50px;
			li{
				margin-bottom: 50px;
			}
			@include mq{
				margin-bottom: -30px;
				li{
					margin-bottom: 30px;
				}
			}
		}
		.ico{
			margin: 0 0 30px;
			@include mq(md-min){
				margin-bottom: 48px;
				width: 120px;
				height: 120px;
			}
		}
		.ttl-03{
			margin-bottom: 15px;
		}
	}
	&-take{
		background: url(../img/service/bg-03.png) no-repeat top center;
		position: relative;
		text-align: center;
		@include mq{
			padding-top: 50px;
			padding-bottom: 30px;
			background: url(../img/service/bg-03-sp.png) no-repeat top center;
			background-size: 100% auto;
		}
		.container{
			max-width: 100%;
		}
		.ttl-02{
			width: 100%;
			@include mq(md-min){
				top: vw(90);
				position: absolute;
				font-size: vw(36);
				padding-bottom: vw(20);
				left: 0;
				&:after{
					width: vw(85);
				}

			}
		}
		.txt-des{
			@include mq(md-min){
				top: vw(184);
				position: absolute;
				width: 100%;
				left: 50%;
				transform: translateX(-50%);
				font-size: vw(15);
				@include mq(xl){
					font-size: vw(22);
				}
			}
			@include mq{
				margin-bottom: 30px;
			}
		}
		.img-main{
			width:vw(1760);
			@include mq(xl){
				margin: 0 auto;
				width:vw2(1000);
			}
			@include mq{
				margin-bottom: 30px;
				width: auto;
			}
		}
		&__list{
			position: relative;
			display: inline-block;
			left: 50%;
			transform: translateX(-50%);
			&__item{
				display: inline-flex;
				align-items: flex-start;
				@include mq(md-min){
					position: absolute;
				}
				@include mq(md-min){
					max-width: vw(550);
				}
				@include mq{
					margin-bottom: 60px;
				}
				.ico{
					margin-bottom: 10px;
					width: 120px;
					height: 120px;
					background: #fff;
					flex-shrink: 0;
					img{
						max-width: 60%;
					}
					@include mq(md-min){
						width: vw(120);
						height: vw(120);
						margin-bottom: vw(10);
					}
					@include mq{
						width: 90px;
						height: 90px;
					}
				}
				.ttl-03{
					color:#fff;
					text-align: right;
					text-transform: uppercase;
					margin-bottom: 10px;
					@include mq(md-min){
						font-size: vw(18);
						margin-bottom: vw(10);
						// @include mq(xl){
						// 	font-size: vw(26);
						// }
					}
					@include mq{
						text-align: left;
						width: 100%;
					}
				}
				.txt-basic{
					color:#fff;
					text-align: right;
					@include mq(md-min){
						font-size: vw(15);
						max-width: vw(380);
						// @include mq(xl){
						// 	font-size: vw(20);
						// }
					}
					@include mq{
						text-align: left;
					}
				}
				&:nth-child(2n+2){
					@include mq(md-min){
						.service-take__list__content{
							justify-content: flex-start;
						}
						.ttl-03{
							text-align: left;
						}
						.service-take__list__content{
							order:2;
							padding-left: vw(30);
							padding-right: 0;

						}
						.ico{
							order:1;
						}
						.txt-basic{
							text-align: left;
						}
				}
				@include mq{
					.service-take__list__content{
						justify-content: flex-start;
					}
					.service-take__list__content{
						order:2;
						padding-left: 20px;
						padding-right: 0;

					}
					.ico{
						order:1;
					}
					.ttl-03{
						text-align: right;
					}
					.txt-basic{
						text-align: right;
					}
					}
				}
				&:nth-child(1){
					@include mq(md-min){
						left: vw(148);
						top: vw(426);
						// @include mq(xl){
						// 	left: vw2(31);
						// 	top: vw2(275);
						// }
					}
				}
				&:nth-child(2){
					@include mq(md-min){
						right: vw(141);
						top: vw(425);
						// @include mq(xl){
						// 	right:vw2(36);
						// }
					}
				}
				&:nth-child(3){
					@include mq(md-min){
						left: vw(144);
						top: vw(702);
						// @include mq(xl){
						// 	top:vw2(520);
						// 	left: vw2(16);
						// }
					}
				}
				&:nth-child(4){
					@include mq(md-min){
						right: vw(138);
						top: vw(704);
						// @include mq(xl){
						// 	top:vw2(535);
						// 	right: vw2(8);
						// }
					}
				}
			}
			&__content{
				width: calc(100% - 120px);
				padding-right: 30px;
				padding-top: 10px;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
				@include mq(md-min){
					padding-top: vw(10);
					padding-right: vw(30);
					width: 100%;
				}
				@include mq{
					padding-right: 20px;
					width: calc(100% - 90px);
					padding-top: 0
				}
			}
		}
	}
	&-form{
		padding: 100px 0;
		background: #f6f6f6;
		@include mq{
			padding: 50px 0;
		}
		.ttl-02{
			margin-bottom: 50px;
		}
		&__wrap{
			background: #fff;
			padding: 50px 50px;
			@include mq{
				padding: 30px 20px;
			}
		}
	}
	&-flow{
		padding: 100px 0 200px	;
		background: url(../img/service/pl/bg-01.png) no-repeat top center;
		@include mq{
			padding: 50px 0 140px;
		}
		.ttl-02{
			margin-bottom: 50px;
		}
		&__list{
			list-style: none;
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			list-style: none;
			margin: 0 -15px;
			li{
				padding: 0 15px;
				position: relative;
				width: calc(100%/8);
				@include mq(lg){
					padding: 0 10px;
				}
				@include mq{
					width: 100%;
					margin-bottom: 30px;
					display: flex;
				}
				span{
					display: inline-flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					width: 100%;
					color:#fff;
					font-size: 16px;
					background: $color-blue;
					min-height: 100px;
					padding: 5px;
					margin-bottom: 50px;
					position: relative;
					@include mq(xl){
						padding: 10px;
						font-size: 14px;
					}
					@include mq(lg){
						margin-bottom: 30px;
					}
					@include mq{
						width: 120px;
						margin-bottom: 0;
						margin-right: 30px;
						&:last-child{
							margin-right: 0;
						}
					}
					&:first-child{
						&:after{
							content:"";
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							width: 30px;
							height: 1px;
							background: #000;
							right: -30px;
							display: block!important;
							@include mq(lg){
								width: 20px;
								right: -20px;
							}
							@include mq{
								bottom:-30px;
								top:auto;
								left: 50%;
								height: 30px;
								width: 1px;
								right:auto;
								transform: translateX(-50%);
							}
						}
						&:before{
							display: none !important;
						}
					}
					&:before{
						content:"";
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						width: 1px;
						height: 50px;
						background: #000;
						top:-50px;
						@include mq(lg){
							height: 30px;
							top: -30px;
						}
						@include mq{
							width: 30px;
							height: 1px;
							left:-30px;
							transform: translateY(-50%);
							top:50%;
						}
					}
					&:last-child{
						margin-bottom: 0;
						&:after{
							display: none;
						}
					}
				}
				&:last-child{
					span{
						&:first-child{
							&:after{
								display: none!important;
							}
						}
					}
				}
			}
			&--style02{
				li{
					width: 10%;
					span{
						margin-bottom: 20px;
						&:nth-child(n+2){
							&:before{
								content:"";
								width: 15px;
								height: calc(50% + 50px + 20px);
								bottom: calc(50%);
								top:auto;
								transform: translateY(0);
								left: -15px;
								position: absolute;
								background: transparent;
								border-left: solid 1px #000;
								border-bottom: solid 1px #000;
							}
							&:after{
								content:"";
								width: 15px;
								height: calc(50% + 50px + 20px);
								bottom: calc(50%);
								top:auto;
								transform: translateY(0);
								background: transparent;
								position: absolute;
								right: -15px;
								border-right: solid 1px #000;
								border-bottom: solid 1px #000;
								display: block!important;
							}
						}
					}
					@include mq(lg){
						padding: 0 5px;
						span{
							padding: 5px;
							font-size: 12px;
							&:after{
								width: 10px;
								right: -10px;
							}
							&:nth-child(n+2){
								&:before, &:after{
									width: 5px;
								}
								&:before{
									left: -5px;
								}
								&:after{
									right: -5px;
								}
							}
						}
					}
					@include mq{
						width: 100%;
						span{
							margin-bottom: 0;
							&:nth-child(n+2){
								height:100px;
								width: auto;
								max-width: 180px;
								padding: 10px 20px;
								&:before{
									content:"";
									height: 1px;
									width: 30px;
									bottom: auto;
									top:50%;
									transform: translateY(0);
									left: -30px;
									position: absolute;
									background: #000;
									// border-right: solid 1px #000;
									border-left: 0;
									border-bottom: 0;
								}
								&:after{
									display: none!important;
								}
							}
						}
					}
				}
			}
		}
	}
	&-scope{
	    background: url(../img/service/shape01.png) no-repeat top 15px center;
	    margin-top: -120px;
	    padding-top: 146px;
	    padding-bottom: 300px;
		@include mq{
			padding-top: 50px;
			padding-bottom: 250px;
			margin-top: -65px;
		}
		&--style02{
			margin-bottom: 0!important;
			padding-bottom: 200px;
			@include mq{
			padding-bottom: 180px;
			}
		}
		.ttl-02{
			margin-bottom: 100px;
			@include mq{
				margin-bottom: 50px;
			}
		}
		&__list{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: -72px;
			list-style: none;
			padding-right: 128px;
			@include mq(lg){
				padding-right: 0;
			}
			@include mq{
				margin-bottom: -30px;
			}
			>li{
				padding: 0 20px;
				margin-bottom: 72px;
				width: 33.33%;
				@include mq(xl){
					padding: 0 10px;
				}
				@include mq{
					width: 50%;
					margin-bottom: 30px;
					overflow: hidden;
				}
				&:nth-child(1),&:nth-child(4){
					overflow: hidden;
				}
				&:nth-child(3n+3){
					.ico{
						&:before{
							width: 160px;
						}
					}
				}
				&:nth-child(2){
					overflow: hidden;
				}
				&:nth-child(3){
					.ico{
						&:after{

							@include mq(lg-min){
								width: 223px;
								height: 253px;
								left: calc(100% + 5px);
								right:auto;
								top: 71px;
								background: url(../img/service/pl/shape01.png) no-repeat;
								background-size: 100% 100%;
								transform: translate(0);
								@include mq(xl){
									width: 180px;
								}
							}
							@include mq(lg){
								content:"";
								position: absolute;
								height: 10px!important;
								width: 300%;
								background: #ebebeb;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
								z-index: -1;
							}
						}
					}
					@include mq(lg){
						overflow: hidden;
					}
				}
				&:nth-child(6){
					i{
						display: none;
					}
					.ico{
						&:after{
							@include mq(lg-min){
								width: 223px;
								height: 253px;
								left: calc(100% + 5px);
								right:auto;
								top:auto;
								bottom: 71px;
								background: url(../img/service/pl/shape02.png) no-repeat;
								background-size: 100% 100%;
								transform: translate(0);
								@include mq(xl){
									width: 180px;
								}
							}
							@include mq(lg){
								content:"";
								position: absolute;
								height: 10px!important;
								width: 200%;
								background: #ebebeb;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
								z-index: -1;
								display: none;
							}
						}
					}
					@include mq(lg){
						overflow: hidden;
					}
				}
				i{
					display: none;
				}
				@include mq(lg){
					position: relative;
					i{
						display: block;
						position: absolute;
						top: 115px;
						right: 0;
						color:$color-orange;
						font-size: 24px;
						z-index: 2;
						@include mq{
							top: 65px;
						}
					}
				}
			}
			.list01{
				li{
					padding-left: 18px;
					margin-left: 30px;
					margin-bottom: 4px;
					&:last-child{
						margin-bottom: 0;
					}
					&:before{
						width: 7px;
						height: 7px;
					}
					@include mq(xl){
						margin-left: 0;
					}
					@include mq{
						font-size: 14px;
						&:before{
							top: 8px;
						}
					}
				}
			}
			.ico{
				position: relative;
				background: #fff;
				margin-bottom: 30px;
				&:before{
					content:"";
					position: absolute;
					height: 10px;
					width: 200%;
					background: #ebebeb;
					right: 100%;
					top: 50%;
					transform: translateY(-50%);
					z-index: -1;
					@include mq(lg){
						width: 300%;
					}
					@include mq{
						z-index: 1;
					}
				}
				&:after{
					content:"";
					position: absolute;
					height: 10px;
					width: 200%;
					background: #ebebeb;
					left: 100%;
					top: 50%;
					transform: translateY(-50%);
					z-index: -1;
					@include mq(lg){
						width: 300%;
					}
					@include mq{
						z-index: 1;
					}
				}
				@include mq{
					width: 100px;
					height: 100px;
					img{
						max-width: 45px;
					}
				}
			}
			&--style02{
				padding-right: 0;
				>li{
					width: 25%;
					&:nth-child(3), &:nth-child(6){
						.ico{
							&:after{
								content:"";
								position: absolute;
								height: 10px!important;
								width: 200%;
								background: #ebebeb;
								left: 100%;
								top: 50%;
								transform: translateY(-50%);
								z-index: -1;
							}
						}

					}
					@include mq(lg){
						width: 50%;
					}
					@include mq{
						// width: 100%;
					}
				}
			}
		}
		&__ttl{
			text-align: center;
			padding-bottom: 20px;
			font-weight: bold;
			font-size: 18px;
			@include mq{
				font-size: 14px;
				padding-bottom: 10px;
			}
		}
	}
}

.service-main{
	background: #fff!important;
}
.box-object{
	margin-bottom: 150px;
	&:last-child{
		margin-bottom: 0;
	}
	@include mq{
		margin-bottom: 30px;
	}
	.row{
		margin: 0 -35px;
		@include mq(xl){
			margin: 0 -15px;
		}
		@include mq{
			.order-1{
				order:1;
			}
			.order-2{
				order:2;
			}
		}
	}
	.col-12{
		padding: 0 35px;
		@include mq(xl){
			padding: 0 15px;
		}
	}
	&__accord{
		@include mq{
			margin-bottom: 30px;
		}
	}
	&__slide{
		@include mq{
			margin-bottom: 30px;
		}
		.slick-dots{
			bottom:-45px;
			li{
				&.slick-active{
					background: $color-blue;
				}
			}
			@include mq{
				bottom:-20px;
			}
		}
	}
	&__slide{
		.img{
			&:before{
				padding-bottom: calc(680/700*100%);
			}
		}
	}
}
.accord{
	margin-bottom: 11px;
	&:last-child{
		margin-bottom: 0;
	}
	&__ttl{
		border:solid 1px #e1e1e1;
		padding: 18px 34px;
		background: #f6f6f6;
		font-weight: 500;
		color:$color-blue;
		font-size: 18px;
		text-transform: uppercase;
		cursor: pointer;
		@include mq{
			font-size: 16px;
			padding: 8px 10px;
		}
	}
	&__content{
		font-size: 15px;
		line-height: 1.6;
		padding: 27px 30px;
		border:solid 1px #e1e1e1;
		border-top: 0;
		display: none;
		@include mq{
			padding: 20px 10px;
		}
	}

}
