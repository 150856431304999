@keyframes animation1 {
  0%   {opacity:0;transform:translate(0, 0);}
  40%  {opacity:1;}
  80%  {opacity:0;transform:translate(0, 10px);}
  100% {opacity:0;}
}
@keyframes scroll-down {
  0% {
    transform: translateY(0);
    // opacity: 0;
  }
  50% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(160px);
    opacity: 0;
  }
}
@keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@-webkit-keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
