.list{
	list-style: none;
	&__ico{
		list-style: none;
		max-width: 1180px;
		margin: 0 auto 60px;
		li{
			margin-bottom: 30px;
		}
	}
	&__img{
		list-style: none;
		margin: 0 -25px -50px;
		@include mq{
			margin: 0 -10px -20px;
		}
		li{
			padding: 0 25px;
			margin-bottom: 50px;
			@include mq{
				padding: 0 10px;
				margin-bottom: 20px;
			}
		}
		&:before{
			padding-bottom: calc(355/405*100%);
		}
	}
}
.repair{
	&-capability{
		@include mq{
			margin-bottom: 100px;
		}
	}
	&-modes{
	    padding: 100px 0 300px;
		background: url(../img/service/pl/bg-01.png) no-repeat top center;
		@include mq{
			padding: 50px 0 250px;
		}
	}
	.product-purchase{
		// background-color:#ebebeb;
	}
}
.pb-100{
	padding-bottom: 100px !important;
}
