.career{
	&-cate{
		padding-bottom: 200px!important;
		@include mq{
			padding-bottom: 130px !important;
		}
		&__list{
			list-style: none;
			max-width: 1200px;
			margin: 0 auto -50px;
			@include mq{
				margin-bottom: -30px;
			}
		}
		.ico{
			margin-bottom: 30px;
			transition: .3s;
			@include mq{
				margin-bottom: 10px;
			}
		}
		.ttl-03{
			color:#333;
			text-align: center;
			text-transform: uppercase;
		}
		li{
			margin-bottom: 50px;
			@include mq{
				margin-bottom: 30px;
			}
			a{
				img{
					transition: .3s;
				}
				&:hover{
					.ico{
						background: $color-orange;
						img{
							filter: brightness(0) invert(1);
						}
					}
					.ttl-03{
						color:$color-orange;
						transition: .3s;
					}
				}

			}
		}
	}
	&-job{
		padding-bottom: 100px;
		border-bottom:solid 1px #ebebeb;
		background-image: url(../img/service/shape01-gray.png);
		@include mq{
			padding-bottom: 50px;
			background-size: cover;
		}
		.txt-des{
			margin-bottom: 50px;
		}
		table{
			max-width: 1100px;
			margin: 0 auto;
		}
	}
}
.table01{
	table-layout: fixed;
    max-width: none;
    width: auto;
    min-width: 100%;
	thead{
		background: $color-orange;
		color:#fff;
		border-bottom: 0;
	}
	td,th{
		padding: 22px 30px;
		font-weight: 400;
		font-size: 18px;
		width: 50%;
		@include mq{
			padding: 16px 15px;
			font-size: 14px;
		}
	}
	tr{
		@include mq{
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			flex-wrap: wrap;
			td{
				display: inline-block;
			}
		}
	}
	td{
		&:nth-child(2){
			padding-left: 0;
			padding-right: 0
		}

	}
	ul{
		list-style: none;
		li{
			font-size: 18px;
			margin-bottom: 10px;
			a{
				// padding-left: 15px;
			    // position: relative;
			    // margin-bottom: 10px;
				// &:before{
				// 	content: "";
				//     position: absolute;
				// 	background: #333;
				//     height: 6px;
				//     width: 6px;
				//     top: 9px;
				// 	left: 0;
				// 	border-radius: 50%;
				// }
				font-size: 13px;
				color:$color-blue;
				&:hover{
					color:$color-orange;
				}
			}
			@include mq{
				font-size: 14px;
			}
		}
	}
	tbody{
		border:solid 1px #ebebeb;
		background: #fff;
		tr{
			border-bottom:solid 1px #ebebeb;
			&:last-child{
				border-bottom: 0;
			}
		}
	}
}
.career-detail{
	font-size: 14px;
	margin-bottom: 30px;
	width: calc(200% + 56px);
	padding-bottom: 20px;
	border-bottom: solid 1px #ddd;
	border-top: solid 1px #ddd;
	padding: 20px 30px 20px;
	margin-left: calc(-100% - 56px);
	margin-top: 20px;
	display: none;
	@include mq{
		width: calc(200% + 30px);
		margin-left: calc(-100% - 30px);
	}
	&__info{

			li{
				font-size: 14px!important;
			}
		strong{
			font-weight: bold;
		}
	}
	&__des{
		margin-bottom: 30px;
		strong{
			display: block;
		}
	}
}
.list03{
	li{
		position: relative;
		padding-left: 15px;
		font-size: 14px!important;
		&:before{
			content:"";
			position: absolute;
			width: 4px;
			height: 4px;
			left: 0;
			top: 9px;
			border-radius: 50%;
			background: #333;;
		}
	}
}
